<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24" class="mr-5">
        <a-form-item required label="Teacher's Name">
          <a-select
            show-search
            size="large"
            class="w-100"
            :value="dataPMA.idGuru"
            :filter-option="filterOption"
            @change="(e) => handleChange(e, 'idGuru')"
          >
            <a-select-option v-for="guru in listGuru" :key="guru.id" :value="guru.id"> {{ guru.nama }} </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item required label="Occupation">
          <a-select
            size="large"
            class="w-100"
            :value="dataPMA.jabatan"
            @change="(e) => handleChange(e, 'jabatan')"
          >
            <a-select-option v-for="jabatan in listJabatan" :key="jabatan.key" :value="jabatan.value"> {{ jabatan.tag }} </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="24" v-if="dataPMA.jabatan === 'wakasek'">
        <a-form-item label="Division">
          <a-select
            size="large"
            class="w-100"
            :value="dataPMA.bagian"
            @change="(e) => handleChange(e, 'bagian')"
          >
            <a-select-option v-for="division in listDivision" :key="division.key" :value="division.value"> {{ division.tag }} </a-select-option>
          </a-select>
          <!-- <a-input
            size="large"
            class="w-100"
            :value="dataPMA.bagian"
            @change="(e) => handleChange(e.target.value, 'bagian')"
          /> -->
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item required label="Status">
          <div class="p-3 border rounded">
            <a-checkbox
              :checked="dataPMA.status === 'active'"
              @change="(e) => handleChange(e.target.checked, 'status')"
            >
              Set as active
            </a-checkbox>
          </div>
          <!-- <a-switch
            size="large"
            :checked="dataPMA.status === 'active'"
            @change="(e) => handleChange(e, 'status')"
          /> -->
          <!-- <a-select
            size="large"
            class="w-100"
            :value="dataPMA.status"
            @change="(e) => handleChange(e, 'status')"
          >
            <a-select-option v-for="status in listStatus" :key="status.key" :value="status.value"> {{ status.value }} </a-select-option>
          </a-select> -->
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Occupation Start Date">
          <a-date-picker
            v-model="dataPMA.mulaiMenjabat"
            format="DD MMMM YYYY"
            style="width: 100%"
            size="large"
            @change="(e) => handleChange(e, 'mulaiMenjabat')"
          />
          <!-- <a-range-picker
            :value="[dataPMA.mulaiMenjabat, dataPMA.selesaiMenjabat]"
            size="large"
            class="w-100"
            :placeholder="['Start Date', 'End Date']"
            @change="(e) => handleChange(e, ['mulaiMenjabat', 'selesaiMenjabat'])"
          /> -->
        </a-form-item>
      </a-col>
      <a-col :span="24" v-if="dataPMA.status !== 'active'">
        <a-form-item label="Occupation Finished Date">
          <a-date-picker
            v-model="dataPMA.selesaiMenjabat"
            format="DD MMMM YYYY"
            style="width: 100%"
            size="large"
            @change="(e) => handleChange(e, 'selesaiMenjabat')"
          />
          <!-- <a-range-picker
            :value="[dataPMA.mulaiMenjabat, dataPMA.selesaiMenjabat]"
            size="large"
            class="w-100"
            :placeholder="['Start Date', 'End Date']"
            @change="(e) => handleChange(e, ['mulaiMenjabat', 'selesaiMenjabat'])"
          /> -->
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    dataPMA: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      listGuru: [],
      listStatus: [{
        key: 1,
        value: 'active',
      }, {
        key: 2,
        value: 'inactive',
      }],
      listJabatan: [{
        key: 1,
        value: 'kepsek',
        tag: 'Kepala Sekolah',
      }, {
        key: 2,
        value: 'wakasek',
        tag: 'Wakil Kepala Sekolah',
      }],
      listDivision: [{
        key: 1,
        value: 'kurikulum',
        tag: 'Kurikulum',
      }, {
        key: 2,
        value: 'kesiswaan',
        tag: 'Kesiswaan',
      }],
    }
  },
  methods: {
    handleChange(value, column) {
      let data = value
      if (column === 'jabatan' || column === 'bagian') {
        data = value.toLowerCase()
      }
      if (column === 'status') data = value ? 'active' : 'inactive'
      const payload = { value: data, column }
      console.log(data)
      this.$emit('handle-change', payload)
    },
    fetchDataGuru() {
      this.$store.dispatch('master/FETCH_TEACHER_LIST')
        .then(res => {
          console.log('fetchDataGuru res => ', res)
          this.listGuru = res.data
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  created() {
    this.fetchDataGuru()
  },
}
</script>

<style>
</style>
